import React, { useState, useEffect } from "react"
import { X } from "lucide-react"
import Button from "./Button"

const DelayedPopup = () => {
  const [isVisible, setIsVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, 10000)

    return () => clearTimeout(timer)
  }, [])

  if (!isVisible) return null

  const handleSubmit = async event => {
    event.preventDefault() // Prevent the form from redirecting
    setLoading(true)

    const formData = new FormData(event.target)
    const response = await fetch(
      "https://mooremomentum.us20.list-manage.com/subscribe/post",
      {
        method: "POST",
        body: formData,
        mode: "no-cors", // Mailchimp might require this to avoid CORS issues
      }
    )

    // After successful submission, trigger the file download
    if (response.ok || response.type === "opaque") {
      setIsVisible(false)
      setLoading(false)
    } else {
      console.error("Error submitting form")
      setLoading(false)
      setIsVisible(false)
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-xl w-full relative">
        <button
          onClick={() => setIsVisible(false)}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>

        <>
          <div className="my-8 text-center">
            <h2 className="font-Orbitron mb-4 text-2xl md:text-3xl">
              Craving a simple, fun, and rewarding system to transform your
              habits?
            </h2>

            <p>
              SUBSCRIBE TO OUR BLOG to receive science-backed tips and
              personalized AI-Driven tools to accelerate your momentum.
            </p>
          </div>

          <form
            action="https://mooremomentum.us20.list-manage.com/subscribe/post"
            method="POST"
            onSubmit={handleSubmit} // Attach the submit handler
          >
            <input type="hidden" name="u" value="705d046da14d7bc6e462e6ec4" />
            <input type="hidden" name="id" value="fc1be8fe04" />
            {/* This hidden input is the blog tag */}
            <input type="hidden" name="tags" value="2083449" />
            {/* Magic hidden input for blog tag */}
            <div className="mb-8 space-y-8">
              <div className="gap-8 grid sm:grid-cols-2">
                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                    id="MERGE1"
                    name="MERGE1"
                    placeholder="First Name"
                    required
                    type="text"
                  />
                </div>

                <div>
                  <input
                    className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                    id="MERGE2"
                    name="MERGE2"
                    placeholder="Last Name"
                    required
                    type="text"
                  />
                </div>
              </div>

              <div>
                <input
                  className="appearance-none border-2 border-gray-dark px-4 py-3 rounded-md text-black w-full focus:border-blue"
                  id="MERGE0"
                  name="MERGE0"
                  placeholder="Email Address"
                  required
                  type="email"
                />
              </div>
            </div>

            <div className="text-center">
              <Button className="px-12" type="submit">
                {loading ? "Subscribing..." : "Subscribe"}
              </Button>
            </div>
          </form>
        </>
      </div>
    </div>
  )
}

export default DelayedPopup
