import "./src/styles/global.css"
const ReactDOM = require("react-dom/client")

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}

export const onClientEntry = () => {
  window.onload = () => {
    setTimeout(() => {
      addScript("https://fast.wistia.com/embed/medias/yrzo75bpk8.jsonp")
      addScript("https://fast.wistia.com/assets/external/E-v1.js")
    }, 5000)
  }

  const handleInteraction = () => {
    import("./loadAnalytics").then(module => {
      module.loadGoogleAnalytics()[
        // Remove listeners after loading
        ("click", "scroll", "mousemove")
      ].forEach(event => window.removeEventListener(event, handleInteraction))
    })
  }

  if (typeof window !== "undefined") {
    // Add listeners for user interaction
    ;["click", "scroll", "mousemove"].forEach(event =>
      window.addEventListener(event, handleInteraction)
    )
  }
}
export const onInitialClientRender = () => {
  document.getElementById("___loader").style.display = "none"
}
